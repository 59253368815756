<template>
  <div id="profile">
    <div class="columns is-multiline">
      <div class="column p-1 is-half-desktop">
        <FormProfile @submit="submit_information" :userData="userData"></FormProfile>
      </div>
      <!-- <div class="column p-1 is-half-desktop is-hidden-mobile">
        <div class="card">
          <header class="card-header">
             <p class="card-header-title">
               จำนวนสาขา: {{branchs.length}}
             </p>
             <button class="card-header-icon button is-small is-info mt-2 mr-2" @click="new_branch=true">
               <span class="mr-2 is-hidden-mobile">เพิ่มสาขา</span>
               <i class="fas fa-plus"></i>
             </button>
           </header>
          <div class="card-content">
            <div class="has-text-centered" v-if="!branchs.length">
              <hr>คุณยังไม่ได้สร้างสาขา
            </div>
            <div class="columns is-multiline" v-else>
              <div class="column is-full" v-for="(val,key) in branchs" :key="key">
                <article class="message is-info">
                  <div class="message-header py-1">
                    <p class="w-100 cursor_pointer" @click="redirect('/member/branch/'+val.id)">
                      <i class="fas fa-charging-station"></i>
                      {{val.name}}
                    </p>
                    <div class="dropdown is-right is-hoverable">
                      <div class="dropdown-trigger">
                        <button class="button is-xs">
                          <i class="fas fa-chevron-down"></i>
                        </button>
                      </div>
                      <div class="dropdown-menu">
                        <div class="dropdown-content">
                          <a class="dropdown-item" @click="branch=val; new_branch=true;">
                            แก้ไขข้อมูล
                          </a>
                          <hr class="dropdown-divider">
                          <a class="dropdown-item has-text-danger" @click="remove_branch(val)">
                            ลบข้อมูล
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="message-body has-text-left p-3 cursor_pointer" @click="redirect('/member/branch/'+val.id)">
                    <small class="is-size-6 is-size-7-mobile">{{val.detail}}</small>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <modal-branch :show="new_branch" :branch="branch" @submit="submit_branch" @close="new_branch=false; branch={};"></modal-branch>

  </div>
</template>

<script>
import FormProfile from '@/components/Form/Profile'
import ModalBranch from '@/components/Modal/Branch'

export default {
  name: 'MemberProfile',
  components:{ FormProfile, ModalBranch },
  props: {
    userData: Object,
    loaded: Object,
    branchs: Array,
  },
  data(){
    return {
      saving: false,
      new_branch: false,
      branch: {},
    }
  },
  // mounted(){
  //   if(!this.loaded.get_branch){
  //     this.$emit('get_branch');
  //   }
  // },
  methods:{
    submit_information(input){
      if(this.saving)return false;

      this.saving = true;
      var url = this.api+'user/save_information/'+this.secret;
      var data = new FormData();
      data.set("name", input.name);
      data.set("lname", input.lname);
      data.set("id", this.userData.id);
      data.set("token", this.userData.token);
      data.set("secret", this.secret);
      this.Axios.post(url, data)
      .then((response)=>{
        console.log(response.data);
        if(response.data.error==0){
          Object.keys(input).forEach((key) => {
            this.userData[key] = input[key]
          });
          this.$swal("","บันทึกข้อมูลสำเร็จ!","success");
        }else{
          this.logout_of_system();
        }
      })
      .catch((error)=>{
        console.error(error);
        this.$swal("","","warning");
      })
      .then(()=>{
        this.saving = false;
      })
    },
    submit_branch(input){
      if(!input.id){
        this.submit_new_branch(input)
      }else{
        this.submit_edit_branch(input)
      }
      this.new_branch = false;
    },
  }
}
</script>
