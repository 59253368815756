<template>
  <div class="card">
    <div class="card-content" style="text-align: left;">

      <form @submit.prevent="submit">
        <strong class="title is-4">Information</strong>
        <hr>

        <div class="field">
          <label class="label">First name</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="text" placeholder="First Name" v-model="input.name">
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
        </div>

        <div class="field">
          <label class="label">Last name</label>
          <div class="control has-icons-left has-icons-right">
            <input class="input" type="text" placeholder="Last Name" v-model="input.lname">
            <span class="icon is-small is-left">
              <i class="fas fa-user"></i>
            </span>
          </div>
        </div>

        <div class="field">
          <label class="label">Email</label>
          <div class="control has-icons-left has-icons-right">
            <span class="input is-info">{{userData.email}}</span>
            <span class="icon is-small is-left has-text-info">
              <i class="fas fa-key"></i>
            </span>
            <span class="icon is-small is-right has-text-info">
              <i class="fas fa-check"></i>
            </span>
          </div>
        </div>

        <div class="field" v-if="adminMode">
          <label class="label">Enable</label>
          <div class="field">
            <label class="switch">
              <input type="checkbox" v-model="enable">
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div class="field is-grouped">
          <div class="control ml-auto">
            <button type="submit" class="button is-link is-small px-5">Save</button>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  props: {
    userData: Object,
    adminMode: Boolean,
  },
  data(){
    return {
      input: {
        name: "",
        lname: "",
      },
      enable: false,
    }
  },
  created(){
    this.set_input();
    this.set_enable();
  },
  methods:{
    set_input(){
      this.input.name = this.userData.name;
      this.input.lname = this.userData.lname;
    },
    set_enable(){
      if(!this.adminMode)return false;
      if(parseInt(this.userData.enable))this.enable = true;
    },
    submit(){
      if(this.adminMode){
        this.input.id = this.userData.id;
        this.input.enable=(this.enable)?1:0;
      }
      this.$emit('submit',this.input);
    }
  },
  watch:{
    userData(val){
      this.input = {};
      if(val.id){
        this.set_input();
        this.set_enable();
      }
    }
  }
}
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 30px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  /* background-color: #2196F3; */
  background-color: #48c78e;
}
input:focus + .slider {
  /* box-shadow: 0 0 1px #2196F3; */
  box-shadow: 0 0 1px #48c78e;
}
input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
</style>
